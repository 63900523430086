export const heroBannerCTASystem = (themeVar) => {
  switch (themeVar) {
    case 1:
      return 'Shop Now';
    case 2:
      return 'Explore';
    case 3:
      return '';
    case 4:
      return '';
    default:
      return 'Shop Now';
  }
};
