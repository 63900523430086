/**
 * * check for existence of logo and store name in config
 * @param {*} deviceWidth number
 * @param {*} config Object
 * @return {*} obj
 */
export const renderStoreName = (deviceWidth = 0, config = {}) => {
  const returnObj = {
    logo: true,
    storeName: true,
  };
  // only for custom website config exist.
  // So for non premium website return true for logo and name
  if (deviceWidth > 720 && config && Object.keys(config).length) {
    return {
      logo: config?.show_logo,
      storeName: config?.show_store_name,
    };
  }
  return returnObj;
};
