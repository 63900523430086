import styled from 'styled-components';

const getBannerHeight = (props, deviceType) => {
  const headerHeight = props.heightCalculation || (deviceType === 'mobile' ? 80 : 90);
  if (props.bannerFixedRatio === 0) {
    // fixed height
    if (deviceType === 'mobile') {
      return `height: calc(100vh - ${headerHeight}px)`;
    } else {
      return `height: calc(100vh - ${headerHeight}px)`;
    }
  } else {
    // fixed aspect ratio
    if (deviceType === 'mobile') {
      return `height: 0; padding-top: ${(16 / 9) * 100}%;`;
    } else {
      return `height: 0; padding-top: ${(9 / 16) * 100}%;`;
    }
  }
};

export const HeroBannerComponent = styled.main`
  display: flex !important;
  width: 100%;
  flex: 1;
  position: relative;
  box-sizing: border-box;

  @media screen and (min-width: 767px) {
    ${(props) => getBannerHeight(props, 'desktop')}
  }

  @media screen and (max-width: 766px) {
    ${(props) => getBannerHeight(props, 'mobile')}
  }
`;

export const HeroBannerImageContainer = styled.div`
  display: flex;
  position: relative;

  @media screen and (min-width: 767px) {
    height: 100%;
    flex: 1;
  }

  @media screen and (max-width: 766px) {
    position: relative;
    height: ${(props) => (props.height ? '50%' : '100%')};
  }
`;

export const HeroBannerImage = styled.section`
  object-fit: cover;
  width: 100%;
  position: relative;

  & > .next-banner-image {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    width: 100%;
  }

  @media screen and (max-width: 766px) {
    & > .next-banner-image {
      object-position: center 25%;
    }
  }
`;

export const HeroBannerInteractivePosition = styled.div`
  @media screen and (min-width: 767px) {
    position: relative;
    height: 100%;
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
  }

  @media screen and (max-width: 766px) {
    position: relative;
    height: 50%;
  }
`;

export const HeroBannerInteractiveBody = styled.div`
  background: #ffffff;
  color: ${(props) => props.fontColor};
  width: 100%;
  box-sizing: border-box;

  @media screen and (min-width: 767px) {
    height: fit-content;
    max-width: 400px;
    padding: 1rem;
  }

  @media screen and (max-width: 766px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 2rem;
  }
`;

export const HeroBannerStoreImage = styled.div`
  border-radius: ${(props) => props.roundness};
  overflow: hidden;
  object-fit: cover;
  overflow: hidden;

  & > .next-store-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }

  @media screen and (min-width: 767px) {
    height: 77px;
    width: 77px;
    margin: 0 auto 20px;
  }

  @media screen and (max-width: 766px) {
    height: 57px;
    width: 57px;
    margin: 0 auto;
  }
`;

export const HeroBannerHeading = styled.h2`
  color: inherit;
  width: 100%;
  margin: 0;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (min-width: 767px) {
    font-size: 39px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 766px) {
    font-size: 27px;
    margin-bottom: 15px;
    margin-top: 5px;
  }
`;

export const HeroBannerDescription = styled.p`
  color: inherit;
  width: 100%;
  text-align: center;
  max-width: 300px;
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;

  @media screen and (min-width: 767px) {
    font-size: 16px;
    max-width: 350px;
    margin: 0 auto 30px;
  }

  @media screen and (max-width: 766px) {
    font-size: 14px;
    margin: 0 auto 15px;
  }
`;

export const ViewMoreSpan = styled.span`
  font-weight: 700;
  cursor: pointer;
  line-height: 1.4;

  &:hover {
    text-decoration: underline;
  }

  @media screen and (min-width: 767px) {
    font-size: 14px;
  }

  @media screen and (max-width: 766px) {
    font-size: 12px;
  }
`;

export const HeroBannerCTAButton = styled.div`
  text-align: center;
  width: 100%;

  & > button {
    margin: 0 auto;
  }
`;

export const HeroBannerMainContent = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 767px) {
    flex-direction: row;
  }
`;
