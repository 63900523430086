/**
 * opening new window to show banner image if image_href exists
 * @param {*} theme
 * @param {*} img
 */
export const bannerImageClick = (theme, img) => {
  if (theme?.custom_theme_flag && img?.image_href) {
    window.open(`${img.image_href}`, '_blank');
  }
};
